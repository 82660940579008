// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-things-to-do-js": () => import("./../../../src/pages/things-to-do.js" /* webpackChunkName: "component---src-pages-things-to-do-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wedding-day-js": () => import("./../../../src/pages/wedding-day.js" /* webpackChunkName: "component---src-pages-wedding-day-js" */),
  "component---src-pages-wedding-party-js": () => import("./../../../src/pages/wedding-party.js" /* webpackChunkName: "component---src-pages-wedding-party-js" */)
}

